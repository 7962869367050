<template>
  <v-sheet class="text-center" color="transparent">
    <v-img
      src="https://www.assessment.com.tr/img/assessment-logo-black.png"
      max-width="220"
      class="mx-auto my-15"
    />
  </v-sheet>

  <NuxtPage v-bind="$attrs" />
</template>

<script lang="ts" setup></script>

<style></style>
